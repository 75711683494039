/**
 * Maritime Connectivity Platform Identity Registry API
 * The MCP Identity Registry API can be used for managing entities in the Maritime Connectivity Platform.<br>Two versions of the API are available - one that requires authentication using OpenID Connect and one that requires authentication using a X.509 client certificate.<br>The OpenAPI descriptions for the two versions are available <a href=\"https://test-api.maritimeconnectivity.net/v3/api-docs/mcp-idreg-oidc\">here</a> and <a href=\"https://test-api-x509.maritimeconnectivity.net/v3/api-docs/mcp-idreg-x509\">here</a>.<br>Additionally, a SECOM based API is also available for which the OpenAPI description can be found <a href=\"https://test-api.maritimeconnectivity.net/v3/api-docs/mcp-idreg-secom\">here</a>.
 *
 * OpenAPI spec version: 1.3.0
 * Contact: info@maritimeconnectivity.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CertificateControllerService {

    protected basePath = 'https://test-api.maritimeconnectivity.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Get the CRL of the specified CA
     * @param caAlias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCRL(caAlias: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCRL(caAlias: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCRL(caAlias: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCRL(caAlias: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caAlias === null || caAlias === undefined) {
            throw new Error('Required parameter caAlias was null or undefined when calling getCRL.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/x-pem-file'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/oidc/api/certificates/crl/${encodeURIComponent(String(caAlias))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * GET mapping for OCSP
     * @param caAlias 
     * @param ocspRequest OCSP request that is encoded as defined in RFC 6960 Appendix A.1
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOCSP(caAlias: string, ocspRequest: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getOCSP(caAlias: string, ocspRequest: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getOCSP(caAlias: string, ocspRequest: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getOCSP(caAlias: string, ocspRequest: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caAlias === null || caAlias === undefined) {
            throw new Error('Required parameter caAlias was null or undefined when calling getOCSP.');
        }

        if (ocspRequest === null || ocspRequest === undefined) {
            throw new Error('Required parameter ocspRequest was null or undefined when calling getOCSP.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/ocsp-response'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/oidc/api/certificates/ocsp/${encodeURIComponent(String(caAlias))}/${encodeURIComponent(String(ocspRequest))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * POST mapping for OCSP
     * @param body OCSP request that is encoded as defined in RFC 6960 Appendix A.1
     * @param caAlias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postOCSP(body: string, caAlias: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public postOCSP(body: string, caAlias: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public postOCSP(body: string, caAlias: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public postOCSP(body: string, caAlias: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postOCSP.');
        }

        if (caAlias === null || caAlias === undefined) {
            throw new Error('Required parameter caAlias was null or undefined when calling postOCSP.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/ocsp-response'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/ocsp-request'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/oidc/api/certificates/ocsp/${encodeURIComponent(String(caAlias))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
